import { AppImages, assignTextStyle, I18N, LARGE_BREAKPOINT, MID_BREAKPOINT, MOBILE_BREAKPOINT, StyleConstants, variantProvider } from '@/app'
import { CenterWrapper, TestimonialCarousel, Text } from '@/components'

const testimonials = [
  {
    name: 'Eletrical',
    avatar: AppImages.AvatarExample,
    job: 'Profession',
    testimonial:
      'Lorem ipsum dolor sit amet',
  },
  {
    name: 'Gardening',
    avatar: AppImages.AvatarExample,
    job: 'Profession',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non.',
  },
  {
    name: 'Renovation',
    avatar: AppImages.AvatarExample,
    job: 'Profession',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis',
  },
  {
    name: 'Cleaning',
    avatar: AppImages.AvatarExample,
    job: 'Profession',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non.',
  },
  {
    name: 'Plumbing',
    avatar: AppImages.AvatarExample,
    job: 'Profession',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non.',
  },
  {
    name: 'Others',
    avatar: AppImages.AvatarExample,
    job: 'Profession',
    testimonial:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non.',
  },
]

export function TestimonialsSection() {
  return (
    <CenterWrapper
      variants={['sectionsPaddings']}
      styles={{
        wrapper: styles.centerWrapper,
        innerWrapper: styles.centerInnerWrapper,
      }}>
      <Text css={styles.text} text={I18N.t('homeSections.testimonials.title')} />
      <TestimonialCarousel testimonials={testimonials}/>
    </CenterWrapper>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    centerWrapper: {
      ...StyleConstants.orangeGradient,
    },
    centerInnerWrapper: {
      ...theme.presets.column,
      ...theme.presets.center,
    },

    text: {
      ...assignTextStyle('h0')(theme).text,
      color: theme.colors.neutral1,
      ...theme.presets.fullWidth,
      ...theme.presets.textCenter,
    },
  }),
  true,
)
